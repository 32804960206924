export const fbcqxxColumn = [
    {
        prop: "xmbh",
        label: "项目编号",
        width: 100,
    },
    {
        prop: "xmmc",
        label: "项目名称",
    },
    {
        prop: "gpjg",
        label: "挂牌总价",
    },
    {
        prop: "zcmj",
        label: "面积",
        width: 70,
        template: true,
    },
    {
        prop: "lznx",
        label: "年限",
        width: 70,
    },
    {
        prop: "lzlx",
        label: "交易类型",
        width: 70,
        template: true,
    },
    {
        prop: "fbsj",
        label: "发布时间",
        width: 90,
    },
    {
        prop: "cqlxbh",
        label: "产权类型",
        width: 120,
        template: true,
    },
    {
        prop: "cjTime",
        label: "成交时间",
        width: 120,
        template: true,
    },
    {
        prop: "cjzj",
        label: "成交金额(元)",
        width: 120,
        template: true,
    },
    {
        prop: "jjzt",
        label: "承接人",
        width: 100,
        template: true,
    },
    {
        prop: "operation",
        label: "操作",
        width: 250,
        template: true,
    },
]
